<template>
  <div class="nosotros">
    <About />
    <MisionVision />
  </div>
</template>

<script>
// @ is an alias to /src
import About from "../components/About.vue";
import MisionVision from "../components/MisionVision.vue";

export default {
  name: "NosotrosView",
  components: {
    About,
    MisionVision,
  },
  mounted() {
    //var URLhash = window.location.hash;

    document.getElementById("inicio").classList.remove("active");
    document.getElementById("nosotros").classList.add("active");
    document.getElementById("servicios").classList.remove("active");
    document.getElementById("catalogo").classList.remove("active");
    document.getElementById("contacto").classList.remove("active");

    window.document.title = "A&G Servis | Nosotros";
  },
};
</script>
