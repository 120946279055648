<template>
  <div class="info" :class="{ 'd-none': info }">
    <div class="container-fluid">
      <div class="row text-center py-2">
        <div class="col-md-4 m-0 py-1">
          <a class="informacion">
            <i class="fas fa-phone-alt" style="font-size: 1.5rem"></i>  +51 928
            951 127    ||    +51 934 744 760</a
          >
        </div>
        <div class="col-md-4 m-0 py-1">
          <a class="informacion">
            <i class="fas fa-home" style="font-size: 1.5rem"></i>   Mz. 3F
            Lt.16, El mirador de Pachacutec123, Ventanilla - Callao</a
          >
        </div>
        <div class="col-md-4 m-0 py-1">
          <a class="informacion">
            <i class="fas fa-clock" style="font-size: 1.5rem"></i>   Lun - Vie
            de 8:00 a 18:30 y Sab de 8:00 a 13:00</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  data() {
    return {
      info: null,
      //ancho: document.body.clientWidth,
    };
  },
  methods: {
    hiden() {
      var ancho = document.body.clientWidth;
      if (ancho < 992) {
        this.info = true;
        //console.log("si");
      } else {
        this.info = false;
        //console.log("no");
      }
      //if (this.ancho > 922) {

      //}
    },
  },
  mounted() {
    this.hiden();
    window.addEventListener("resize", this.hiden);
  },
};
</script>

<style scoped>
.info {
  background-color: rgb(58, 58, 58);
}

.informacion {
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
}

.informacion:hover {
  color: #ffd52d;
}
</style>
