<template>
  <div>
    <!-- Contact Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 class="section-title bg-white text-center text-primary px-3">
            Contactanos
          </h6>
          <h1 class="mb-5">Escríbenos para cualquier consulta</h1>
        </div>
        <div class="row g-4">
          <div class="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
            <h5>Información</h5>
            <p class="mb-4">
              <!--  
                El formulario de contacto está actualmente inactivo. 
                Obtenga un formulario de contacto funcional y funcional 
                con Ajax y PHP en unos minutos. Simplemente copie y pegue los archivos
                , agregue un pequeño código y listo.
              -->

              <!-- <a href="https://htmlcodex.com/contact-form">a</a>. -->
            </p>
            <div class="d-flex align-items-center mb-3">
              <div
                class="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                style="width: 50px; height: 50px"
              >
                <i class="fa fa-map-marker-alt text-white"></i>
              </div>
              <div class="ms-3">
                <h5 class="text-primary">Ubicación</h5>
                <p class="mb-0">
                  Asoc. Virgen de Guadalupe Lurigancho - Chosica
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <div
                class="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                style="width: 50px; height: 50px"
              >
                <i class="fa fa-phone-alt text-white"></i>
              </div>
              <div class="ms-3">
                <h5 class="text-primary">Celular</h5>
                <p class="mb-0">+51 934 744 760</p>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div
                class="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                style="width: 50px; height: 50px"
              >
                <i class="fa fa-envelope-open text-white"></i>
              </div>
              <div class="ms-3">
                <h5 class="text-primary">Email</h5>
                <p class="mb-0">info@aygservis.com</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
            <div class="row g-3">
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="Nombre"
                    v-model="name"
                  />
                  <label for="name">Nombre</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Correo electrónico"
                    v-model="email"
                  />
                  <label for="email">Correo electrónico</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="subject"
                    placeholder="Asunto"
                    v-model="subject"
                  />
                  <label for="subject">Asunto</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Mensaje"
                    id="message"
                    style="height: 150px"
                    v-model="message"
                  ></textarea>
                  <label for="message">Mensaje</label>
                </div>
              </div>
              <div class="col-12">
                <button
                  class="btn btn-primary w-100 py-3"
                  v-on:click="sendEmail"
                >
                  Enviar mensaje
                </button>
              </div>
            </div>
          </div>

          <div class="col-12 wow fadeInUp" data-wow-delay="0.3s">
            <iframe
              class="position-relative rounded w-100 h-100"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1952.4802668887214!2d-77.1653801276578!3d-11.838036499999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105d4eedb0682fb%3A0x60d94baa76420276!2sAv.%20El%20Mirador%2C%20Ventanilla%2007071!5e0!3m2!1ses-419!2spe!4v1647993131825!5m2!1ses-419!2spe"
              frameborder="0"
              style="min-height: 400px; border: 0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact End -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
      correo: {},
    };
  },
  methods: {
    enviarMensaje() {
      this.correo = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
      };
      //console.log(this.correo);
    },

    sendEmail(e) {
      e.preventDefault();

      axios
        .post("localhost:3000/send-email", {
          //name: this.name,
          email: this.email,
          //subject: this.subject,
          //message: this.message,
        })
        .then((response) => {
          console.log("Se envió el correo");
        })
        .catch((error) => {
          console.log("No se envió el correo");
          console.log(error);
          //this.errored = true
        });
      //.finally(() => this.loading = false)
    },
  },
};
</script>

<style scoped></style>
