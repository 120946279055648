import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'



import '@/assets/lib/animate/animate.min.css'


import '@/assets/css/bootstrap.min.css'
import '@/assets/css/style.css'

//import '@/assets/js/jquery-3.4.1.min.js'
//import '@/assets/js/bootstrap.bundle.min.js'


//import '@/assets/lib/wow/wow.js'
new WOW().init();
import '@/assets/lib/easing/easing.min.js'
import '@/assets/lib/waypoints/waypoints.min.js'

import '@/assets/lib/sss/sss.js'

import '@/assets/js/main.js'

createApp(App).use(store).use(router).mount('#app')

$('.owl-carousel').owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1000:{
            items:5
        }
    }
})

