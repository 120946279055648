<template>
  <div>
    <!-- Navbar Start -->
    <div id="navbar_height"></div>
    <nav
      id="navbar"
      class="navbar navbar-expand-lg bg-dark navbar-dark shadow sticky-top p-0 w-100"
    >
      <!--:class="{ 'position-fixed': logo_swap }"-->
      <a
        href="index.html"
        class="navbar-brand d-flex align-items-center px-4 px-lg-5"
      >
        <h2 class="m-0 text-primary">
          <!-- {{ ancho }}  {{ logo_swap }}  -->

          <template v-if="logo_swap">
            <img
              src="../assets/img/logoIcon.png"
              style="font-size: 10%"
              alt=""
            />
          </template>
          <template v-else>
            <img src="../assets/img/logo5.png" style="font-size: 10%" alt="" />
          </template>
        </h2>
      </a>
      <button
        type="button"
        class="navbar-toggler me-4 collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        v-on:click="menu"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse text-center"
        :class="{ show: show }"
        id="navbarCollapse"
      >
        <div class="navbar-nav ms-auto p-4 p-lg-0">
          <router-link
            id="inicio"
            to="/"
            class="nav-item nav-link"
            :class="{ active: inicio }"
            v-on:click="menu"
            >INICIO</router-link
          >
          <router-link
            id="nosotros"
            to="/nosotros"
            class="nav-item nav-link"
            :class="{ active: nosotros }"
            v-on:click="menu"
            >NOSOTROS</router-link
          >
          <router-link
            id="servicios"
            to="/servicios"
            class="nav-item nav-link"
            :class="{ active: servicios }"
            v-on:click="menu"
            >SERVICIOS</router-link
          >
          <router-link
            id="catalogo"
            to="/catalogo"
            class="nav-item nav-link"
            :class="{ active: catalogo }"
            v-on:click="menu"
            >CATÁLOGO</router-link
          >
          <router-link
            id="contacto"
            to="/contacto"
            class="nav-item nav-link"
            :class="{ active: contacto }"
            v-on:click="menu"
            >CONTACTO</router-link
          >
        </div>
        <router-link
          id="contacto2"
          to="/contacto"
          class="btn btn-primary py-4 px-lg-5 d-none d-lg-block"
          >CONTACTANOS
          <i class="fa fa-arrow-right ms-3"></i>
        </router-link>
      </div>
    </nav>
    <!-- Navbar End -->
  </div>
</template>

<script>
export default {
  name: "NavBar2",

  data() {
    return {
      inicio: true,
      nosotros: false,
      catalogo: false,
      servicio: false,
      contacto: false,
      show: false,
      logo_swap: false,
      ancho: document.body.clientWidth,
    };
  },

  methods: {
    menu() {
      this.show = !this.show;
      //console.log(this.$route.path);
      //this.inicio = !this.inicio;
      //console.log(window.location.hash);
      //if (window.location.pathname === ''){

      //}
    },

    getHeightNavBar(navbar) {
      var navbar_height = document.getElementById("navbar_height");

      //var aux = 0;
      navbar_height.style.setProperty("height", 0 + "px");
      if (this.ancho < 992) {
        $(".navbar_height").css("height", "0px");
        navbar_height.style.setProperty("height", navbar.clientHeight + "px");
        /*
        if (navbar.clientHeight === aux) {
          aux = 0;
          //navbar_height.style.setProperty("height", aux + "px");
        } else {
          navbar_height.style.setProperty("height", navbar.clientHeight + "px");
        }
        //aux = navbar.clientHeight;
        */
      } else {
        $(".navbar_height").css("height", "0px");
      }
    },

    ocultar() {
      //this.getHeightNavBar();

      if (this.ancho < 992) {
        this.logo_swap = true;
        $(".navbar_height").css("height", "0px");
        $(".navbar").removeClass("position-sticky");
        $(".navbar").css("top", "0px");
        $(".navbar").addClass("position-fixed");
        //$(".navbar_height").css("height", "0px");
        //this.getHeightNavBar();
        //console.log("si");
      } else {
        // Sticky Navbar
        //$(".navbar").addClass("sticky-top");
        $(".navbar_height").css("height", "0px");
        $(window).scroll(function () {
          if ($(this).scrollTop() > 150) {
            $(".navbar").removeClass("position-sticky");
            $(".navbar").css("top", "0px");
            $(".navbar").addClass("position-fixed");
          } else {
            $(".navbar").css("top", "-50px");
            $(".navbar").removeClass("position-fixed");
            //$(".navbar_height").css("height", "0px");
            $(".navbar").addClass("position-sticky");
          }
        });
        this.logo_swap = false;
        //console.log("no");
      }
      //if (this.ancho > 922) {

      //}
    },
  },

  mounted() {
    var navbar = document.getElementById("navbar");
    $(".navbar").addClass("position-sticky");
    this.ocultar();
    const that = this;
    window.onresize = () => {
      return (() => {
        window.ancho = document.body.clientWidth;
        that.ancho = window.ancho;
      })();
    };
    window.addEventListener("resize", this.ocultar);
    window.addEventListener("resize", this.getHeightNavBar(navbar));
  },
};
</script>

<style scoped>
.ocultar {
  visibility: hidden;
  display: none;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #ffd52daf;
}

.navbar-dark .navbar-nav .nav-link:active {
  color: #ffd52d;
}
</style>
