<template>
  <div class="contacto">
    <Contact />
  </div>
</template>

<script>
import Contact from "../components/Contact.vue";
// @ is an alias to /src
//import About from '../components/About.vue';

export default {
  name: "ContactoView",
  components: {
    Contact,
  },
  mounted() {
    document.getElementById("inicio").classList.remove("active");
    document.getElementById("nosotros").classList.remove("active");
    document.getElementById("servicios").classList.remove("active");
    document.getElementById("catalogo").classList.remove("active");
    document.getElementById("contacto").classList.add("active");

    window.document.title = "A&G Servis | Contacto";
  },
};
</script>
