<template>
  <div>
    <!-- About Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            <h6 class="section-title bg-white text-start text-primary pe-3">
              Misión & Visión
            </h6>
            <h1 class="mb-4">Misión</h1>
            <p class="mb-4">
              Satisfacer las espectativas de nuestros clientes, realizando
              nuestros servicios con la mayor calidad, puntualidad y con
              soluciones personalizadas para cada empresa, procurando siempre la
              mejora continua en todos nuestros procesos.
            </p>
            <h1 class="mb-4">Visión</h1>
            <p class="mb-4">
              Contamos con profesionales altamente calificados en todos nuestros
              campos de trabajo con el objetivo de brindar una servicio de
              calidad. Desde su fundacion, A&G Servis ha ido perfeccionando sus
              mecanismos y estrategias de produccion teniendo en cuenta:
            </p>
          </div>

          <div
            class="col-lg-6 wow fadeInUp"
            data-wow-delay="0.1s"
            style="min-height: 400px"
          >
            <div class="position-relative h-100">
              <img
                class="img-fluid position-absolute w-100 h-100"
                src="../assets/img/myv.jpg"
                alt=""
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->
  </div>
</template>

<script>
export default {
  name: "MisvionVision",
};
</script>

<style scoped></style>
