<template>
  <div>
    <!-- Testimonial Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <!-- Titulo -->
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 class="section-title bg-white text-center text-primary px-3">
            Nuestros Clientes
          </h6>
          <h1 class="mb-5">Clientes</h1>
        </div>
        <!-- Fin titulo -->
      </div>
      <!-- Contenido Start -->
      <section class="testimonials-section">
        <div class="container-fluid px-lg-4 px-md-4">
          <div class="row">
            <div class="col-md-12">
              <div class="testimonials">
                <div id="carousel1" class="owl-carousel owl-theme">
                  <div class="item1">
                    <div class="text-center">
                      <img
                        src="../assets/img/cliente1.jpg"
                        class="card-img-top"
                      />
                      <!-- 
                        <div class="card-body">
                        <h5>
                          Ronne Galle <br />
                          <span>Proyect manager</span>
                        </h5>
                        <p class="card-text">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Quod laudantium soluta, sed harum, nesciunt illo
                          ut incidunt illum maiores, ducimus ea. Provident
                          quibusdam vero, perspiciatis sed consequatur nemo
                          aspernatur dignissimos.
                        </p>
                      </div>
                      -->
                    </div>
                  </div>

                  <div class="item2">
                    <div class="text-center">
                      <img
                        src="../assets/img/cliente2.jpg"
                        class="card-img-top opacity-50"
                      />

                      <!--
                        <div class="card-body">
                        <h5>
                          Ronne Galle <br />
                          <span>Proyect manager</span>
                        </h5>
                        <p class="card-text">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Quod laudantium soluta, sed harum, nesciunt illo
                          ut incidunt illum maiores, ducimus ea. Provident
                          quibusdam vero, perspiciatis sed consequatur nemo
                          aspernatur dignissimos.
                        </p>
                      </div>
                       -->
                    </div>
                  </div>

                  <div class="item3">
                    <div class="text-center">
                      <img
                        src="../assets/img/cliente3.jpg"
                        class="card-img-top opacity-50"
                      />
                      <!--
                        <div class="card-body">
                        <h5>
                          Ronne Galle <br />
                          <span>Proyect manager</span>
                        </h5>
                        <p class="card-text">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Quod laudantium soluta, sed harum, nesciunt illo
                          ut incidunt illum maiores, ducimus ea. Provident
                          quibusdam vero, perspiciatis sed consequatur nemo
                          aspernatur dignissimos.
                        </p>
                      </div>
                       -->
                    </div>
                  </div>

                  <div class="item4">
                    <div class="text-center">
                      <img
                        src="../assets/img/cliente4.jpg"
                        class="card-img-top opacity-50"
                      />

                      <!--
                        <div class="card-body">
                        <h5>
                          Ronne Galle <br />
                          <span>Proyect manager</span>
                        </h5>
                        <p class="card-text">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Quod laudantium soluta, sed harum, nesciunt illo
                          ut incidunt illum maiores, ducimus ea. Provident
                          quibusdam vero, perspiciatis sed consequatur nemo
                          aspernatur dignissimos.
                        </p>
                      </div>
                       -->
                    </div>
                  </div>

                  <div class="item5">
                    <div class="text-center">
                      <img
                        src="../assets/img/cliente1.jpg"
                        class="card-img-top opacity-50"
                      />

                      <!--
                        <div class="card-body">
                        <h5>
                          Ronne Galle <br />
                          <span>Proyect manager</span>
                        </h5>
                        <p class="card-text">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Quod laudantium soluta, sed harum, nesciunt illo
                          ut incidunt illum maiores, ducimus ea. Provident
                          quibusdam vero, perspiciatis sed consequatur nemo
                          aspernatur dignissimos.
                        </p>
                      </div>
                       -->
                    </div>
                  </div>

                  <div class="item6">
                    <div class="text-center">
                      <img
                        src="../assets/img/cliente2.jpg"
                        class="card-img-top opacity-50"
                      />

                      <!--
                        <div class="card-body">
                        <h5>
                          Ronne Galle <br />
                          <span>Proyect manager</span>
                        </h5>
                        <p class="card-text">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Quod laudantium soluta, sed harum, nesciunt illo
                          ut incidunt illum maiores, ducimus ea. Provident
                          quibusdam vero, perspiciatis sed consequatur nemo
                          aspernatur dignissimos.
                        </p>
                      </div>
                       -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Contenido End -->
    </div>

    <!-- Testimonial End -->
  </div>
</template>

<script>
import "@/assets/lib/owlcarousel/assets/owl.carousel.min.css";
import "@/assets/lib/owlcarousel/assets/owl.theme.default.min.css";

import "@/assets/lib/owlcarousel/jquery.min.js";
import "@/assets/lib/owlcarousel/owl.carousel.min.js";

/*
var $owl = $("carousel1");
$owl.children().each(function (index) {
  $(this).attr("data-position", index);
});

$(document).ready(function (index) {
  $(".owl-carousel").owlCarousel({
    loop: true,
    center: true,
    margin: 0,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      680: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  });
});

$(document).on("click", "owl-item>div", function () {
  var $speed = 1000;
  $owl.trigger("to.owl.carousel", [$(this).data("position"), $speed]);
});
*/
export default {
  name: "TestimonialPrueba",
  mounted() {
    var $owl = $("carousel1");
    $owl.children().each(function (index) {
      $(this).attr("data-position", index);
    });

    $(document).ready(function (index) {
      $(".owl-carousel").owlCarousel({
        loop: true,
        center: true,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 2500,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 1,
          },
          680: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      });
    });

    $(document).on("click", "owl-item>div", function () {
      var $speed = 1000;
      $owl.trigger("to.owl.carousel", [$(this).data("position"), $speed]);
    });
  },
};

/* INICIO */
/*
var $owl = $("carousel1");
$owl.children().each(function (index) {
  $(this).attr("data-position", index);
});

$("#carousel1").$owlCarousel({
  loop: false,
  center: true,
  margin: 0,
  responsiveClass: true,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
    680: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
});

$(document).on("click", "owl-item>div", function () {
  var $speed = 1000;
  $owl.trigger("to.owl.carousel", [$(this).data("position"), $$speed]);
});
*/
/* FIN */
</script>

<style scoped>
/* INCIO*/

/*
body {
  background: aliceblue;
  font-family: "Quicksand", sans-serif;
}

.testimonials-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

*/
/*
.testimonials .card {
  background: #fff;
  box-shadow: 0 8px 30px -7px #c9dff0;
  margin: 0 20px;
  padding: 0 10px;
  border-radius: 20px;
  border: 0;
}

.testimonials .card .card-img-top {
  max-width: 100px;
  border-radius: 50%;
  margin: 15px auto 0;
  box-shadow: 0 8px 20px -4px #345683;
  width: 100px;
  height: 100px;
}

.testimonials .card h5 {
  color: #01b0f8;
  font-size: 22px;
  line-height: 1.3;
  font-weight: 600;
}

.testimonials .card h5 span {
  font-size: 17px;
  color: #01b0f8;
}
.testimonials .card p {
  font-size: 18px;
  color: #01b0f8;
  padding-bottom: 15px;
}
*/

.owl-item > div {
  cursor: pointer;
  margin: 20% 0%;
  transition: margin 0.4s ease;
  opacity: 0.4;
}

.owl-item.center > div {
  cursor: pointer;
  margin: 0;
  opacity: 1;
}

.testimonials .active {
  opacity: 1;
  transition: all 0.25s ease;
}

/* ESTE DA LA OPACIODAD AL SIGUIOENTE */
/*

.testimonials .center {
  opacity: 0.3;
}

.testimonials .owl-stage-outer {
  padding: 30px 0;
}
*/
/*
.testimonials .owl-dots {
  text-align: center;
}

.testimonials .owl-dots span {
  position: relative;
  height: 14px;
  width: 14px;
  border-radius: 20px;
  display: block;
  background: rgb(172, 104, 104);
  border: 2px solid #01b0f8;
  transition: all 0.25s ease;
  margin: 4px;
}

.testimonials .owl-dots .active span {
  background: #01b0f8;
  box-shadow: none;
  height: 14px;
  width: 30px;
  position: relative;
  transition: all 0.25s ease;
}

.testimonials .owl-dots .active {
  box-shadow: none;
}

.owl-dots .owl-dot:focus {
  box-shadow: none !important;
  outline: 0;
  border: 0;
}

*/

.owl-carousel .owl-item img {
  opacity: 0.4;
}

.owl-carousel .owl-item img {
  opacity: 1;
}

/*FIN*/
</style>
