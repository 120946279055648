<template>
  <div>
    <!-- Carousel Start -->
    <!-- Carousel -->
    <div class="bg-dark">
      <div
        id="carouselExampleCaptions"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="../assets/img/carousel-2.jpg"
              class="d-block w-100"
              alt="..."
            />

            <div class="carousel-caption d-none d-md-block">
              <div class="col-sm-7 col-lg-30">
                <!-- Ubicacion <- -> -->

                <h2
                  class="text-primary text-uppercase mb-3 animated slideInDown"
                >
                  Fabricación de componentes de maquinaria pesada
                </h2>
                <h1 class="display-1 text-white animated slideInDown">
                  <!-- Titulo A&GServis -->
                </h1>
                <p class="fs-1 text-white mb-4 pb-2">
                  Nos adaptamos a sus requerimientos y necesidades.
                </p>
                <!-- BOTON DE SABER MAS
                   <a
                  href=""
                  class="btn btn-primary py-md-3 px-md-5 me-0 animated slideInLeft"
                  >Saber mas
                </a>
                -->

                <!-- me : ubicacion -x ; 0 ; +x -->
                <!--
              <a
                href=""
                class="btn btn-light py-md-3 px-md-5 animated slideInRight"
                >Join Now</a
                -->
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="../assets/img/carousel-4.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block">
              <div class="col-sm-7 col-lg-30">
                <!-- Ubicacion -->

                <h2
                  class="text-primary text-uppercase mb-3 animated slideInDown"
                >
                  BARRENADO/MECANIZADO DE ALOJAMIENTOS
                </h2>
                <h1 class="display-1 text-white animated slideInDown">
                  <!-- Titulo A&GServis -->
                </h1>
                <p class="fs-1 text-white mb-4 pb-2">
                  Recupera y reduce el costo de los componentes.
                </p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="../assets/img/carousel-1.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block">
              <div class="col-sm-7 col-lg-30">
                <!-- Ubicacion -->

                <h2
                  class="text-primary text-uppercase mb-3 animated slideInDown"
                >
                  Servicios especiales de soldadura
                </h2>
                <h1 class="display-1 text-white animated slideInDown">
                  <!-- Titulo A&GServis -->
                </h1>
                <p class="fs-1 text-white mb-4 pb-2">
                  Trabajamos con aceros inoxidables,aluminio, hierro fundido
                  entre otros.
                </p>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <img
              src="../assets/img/carousel-5.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block">
              <div class="col-sm-7 col-lg-30">
                <!-- Ubicacion -->

                <h2
                  class="text-primary text-uppercase mb-3 animated slideInDown"
                >
                  FABRICACION DE ESTRUCTURAS METALICAS
                </h2>
                <h1 class="display-1 text-white animated slideInDown">
                  <!-- Titulo A&GServis -->
                </h1>
                <p class="fs-1 text-white mb-4 pb-2">
                  Para distintos tipos de industria.
                </p>
              </div>
            </div>
          </div>
        </div>

        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>

    <!-- Carousel End -->
  </div>
</template>

<script>
export default {
  name: "Carousel2",
};
</script>

<style scoped>
img {
  opacity: 0.5;
}
</style>
