<template>
  <div>
    <Spinner />
    <Info />
    <nav>
      <!--<NavBar />-->
      <NavBar2 />
      <!--<NavBar />-->
    </nav>
    <router-view />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
//import NavBar from "@/components/NavBar.vue";
//import Menu2 from "@/components/Menu2.vue";
import Footer from "@/components/Footer.vue";
import Spinner from "./components/Spinner.vue";
import Info from "./components/Info.vue";
import NavBar2 from "./components/NavBar2.vue";
//import Carousel2 from "./components/Carousel2.vue";

export default {
  name: "App",
  data() {
    return {
      path: this.$route.path,
    };
  },
  components: {
    //NavBar,
    //Menu2,
    //Carousel2,
    Footer,
    Spinner,
    Info,
    NavBar2,
  },
};
</script>

<style lang="scss"></style>
