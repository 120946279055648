<template>
  <div class="catalogo">
    <div class="container">
      <div class="row my-5">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 class="section-title bg-white text-center text-primary px-3">
            Catálogo
          </h6>
          <h1 class="mb-5">Catálogo</h1>
        </div>
        <template v-for="p in catalogo">
          <div
            class="col-md-4 col-sm-6 p-3 wow fadeInUp"
            data-wow-delay="0.1s "
          >
            <div class="card border-0 shadow">
              <img class="card-img-top" :src="p.img" alt="" />
              <div class="card-body text-center">
                <h5 class="card-title">{{ p.nombre }}</h5>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import About from "../components/About.vue";

export default {
  name: "CatalogoView",
  data() {
    return {
      catalogo: [
        {
          img: require("../assets/img/catalogo/catalogo_1.jpg"),
          nombre: "Parte delantera del vehículo 853 s8 NP 801523",
        },
        {
          img: require("../assets/img/catalogo/catalogo_2.jpg"),
          nombre:
            "Torre giratoria soldada NP 343962                               ",
        },
        {
          img: require("../assets/img/catalogo/catalogo_3.jpg"),
          nombre: "Tubo telescópico del vehículo 853 NP 051157",
        },
        {
          img: require("../assets/img/catalogo/catalogo_4.jpg"),
          nombre: "Tubo telescópico 1",
        },
        {
          img: require("../assets/img/catalogo/catalogo_5.jpg"),
          nombre: "Tubo telescópico 2",
        },
        {
          img: require("../assets/img/catalogo/catalogo_6.jpg"),
          nombre: "Tubo telescópico 3",
        },
        {
          img: require("../assets/img/catalogo/catalogo_7.jpg"),
          nombre: "pala niveladora pieza soldada NP 344389",
        },
        {
          img: require("../assets/img/catalogo/catalogo_8.jpg"),
          nombre: "Placa de montaje de pala frontal NP 34450",
        },
        {
          img: require("../assets/img/catalogo/catalogo_9.jpg"),
          nombre: "Brazo inferior NP 377010                              ",
        },
        {
          img: require("../assets/img/catalogo/catalogo_10.jpg"),
          nombre: "Brazo superior NP 377015                              ",
        },
        {
          img: require("../assets/img/catalogo/catalogo_11.jpg"),
          nombre:
            "Tornillo de ajuste NP 012327 , Tuerca hexagonal plana NP 501870",
        },
        {
          img: require("../assets/img/catalogo/catalogo_12.jpg"),
          nombre: "Montaje de articulación de acodamiento NP 378517",
        },
        {
          img: require("../assets/img/catalogo/catalogo_13.jpg"),
          nombre: "Taco NP 001682 - NP 001683",
        },
        {
          img: require("../assets/img/catalogo/catalogo_14.jpg"),
          nombre: "NP 001684 - NP 003397",
        },
        {
          img: require("../assets/img/catalogo/catalogo_15.jpg"),
          nombre: "Arandela NP 000093",
        },
        {
          img: require("../assets/img/catalogo/catalogo_16.jpg"),
          nombre:
            "                                                                   Guía de desplazamiento del tubo telescópico                                                    ",
        },
        {
          img: require("../assets/img/catalogo/catalogo_17.jpg"),
          nombre:
            "                                                                   PL. UNIV. MONTAKE MARTILLO NP 394320                                                           ",
        },
        {
          img: require("../assets/img/catalogo/catalogo_18.jpg"),
          nombre:
            "PIN(PERNO) NP 375165, 381172, 000342, 334975, 377024, 377028, 377032, 053890, 334972, 053890, 334972, 327021, 001706",
        },
      ],
    };
  },
  components: {},
  mounted() {
    //var URLhash = window.location.hash;

    document.getElementById("inicio").classList.remove("active");
    document.getElementById("nosotros").classList.remove("active");
    document.getElementById("servicios").classList.remove("active");
    document.getElementById("catalogo").classList.add("active");
    document.getElementById("contacto").classList.remove("active");

    window.document.title = "A&G Servis | Catálogo";
  },
};
</script>
