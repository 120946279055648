<template>
  <div class="services">
    <Services />
    <Galeria />
  </div>
</template>

<script>
// @ is an alias to /src
import Services from "../components/Services.vue";
import Galeria from "../components/Galeria.vue";

export default {
  name: "NosotrosView",
  components: {
    Services,
    Galeria,
  },
  mounted() {
    document.getElementById("inicio").classList.remove("active");
    document.getElementById("nosotros").classList.remove("active");
    document.getElementById("servicios").classList.add("active");
    document.getElementById("catalogo").classList.remove("active");
    document.getElementById("contacto").classList.remove("active");

    window.document.title = "A&G Servis | Servicios";
  },
};
</script>
