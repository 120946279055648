<template>
  <div>
    <!-- About Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5">
          <div
            class="col-lg-6 wow fadeInUp"
            data-wow-delay="0.1s"
            style="min-height: 400px"
          >
            <div class="position-relative h-100">
              <img
                class="img-fluid position-absolute w-100 h-100"
                src="../assets/img/nosotros.jpg"
                alt=""
                style="object-fit: cover"
              />
            </div>
          </div>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            <h6 class="section-title bg-white text-start text-primary pe-3">
              Acerca de
            </h6>
            <h1 class="mb-4">Bienvenido a A&G Servis</h1>
            <p class="mb-4">
              Nuestra empresa se fundó en 2018 como respuesta a las necesidades
              del mercado minero y de construcción.
            </p>
            <p class="mb-4">
              Contamos con profesionales altamente calificados en todos nuestros
              campos de trabajo con el objetivo de brindar una servicio de
              calidad. Desde su fundacion, A&G Servis ha ido perfeccionando sus
              mecanismos y estrategias de produccion teniendo en cuenta:
            </p>
            <div class="row gy-2 gx-4 mb-4">
              <div class="col-sm-6">
                <p class="mb-0">
                  <i class="fa fa-arrow-right text-primary me-2"></i>Seguridad
                </p>
              </div>

              <div class="col-sm-6">
                <p class="mb-0">
                  <i class="fa fa-arrow-right text-primary me-2"></i>Integridad
                  Laboral
                </p>
              </div>
              <div class="col-sm-6">
                <p class="mb-0">
                  <i class="fa fa-arrow-right text-primary me-2"></i
                  >Sostenibilidad
                </p>
              </div>
              <div class="col-sm-6">
                <p class="mb-0">
                  <i class="fa fa-arrow-right text-primary me-2"></i>Cuidado
                  Ambiental
                </p>
              </div>
              <div class="col-sm-6">
                <p class="mb-0">
                  <i class="fa fa-arrow-right text-primary me-2"></i
                  >Capacitación Constante
                </p>
              </div>
              <div class="col-sm-6"></div>
            </div>
            <template v-if="url != '/nosotros'">
              <router-link to="/nosotros" class="btn btn-primary py-3 px-5 mt-2"
                >Leer más</router-link
              >
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      url: "",
    };
  },
  mounted() {
    this.url = this.$route.path.toString(); // window.location.href;
  },
};
</script>

<style scoped></style>
