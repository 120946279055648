<template>
  <div>
    <!-- Footer Start -->
    <div
      class="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-2 col-md-6">
            <h4 class="text-white mb-3">Menu</h4>
            <a class="btn btn-link" href="/nosotros">Inicio</a>

            >
            <a class="btn btn-link" href="">Nosotros</a>
            <a class="btn btn-link" href="">Servicios</a>
            <a class="btn btn-link" href="">Contacto</a>
            <!--
                    <a class="btn btn-link" href="">Terms & Condition</a>
                    <a class="btn btn-link" href="">FAQs & Help</a>
                    -->
          </div>
          <div class="col-lg-4 col-md-6">
            <h4 class="text-white mb-3">Contacto</h4>
            <p class="mb-2">
              <i class="fas fa-home me-3"></i>Mz. 3F Lt.16, El mirador de
              Pachacutec, Ventanilla - Callao
            </p>
            <p class="mb-2">
              <i class="fa fa-solid fa-industry me-3"></i>Asoc. Virgen de
              Guadalupe - Chosica
            </p>
            <p class="mb-2">
              <i class="fa fa-phone-alt me-3"></i>+51 928 951 127
            </p>
            <p class="mb-2"><i class="fa me-3"></i>    +51 934 744 760</p>
            <p class="mb-2">
              <i class="fa fa-envelope me-3"></i>info@aygservis.com
            </p>
            <div class="d-flex pt-2">
              <a class="btn btn-outline-light btn-social" href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a class="btn btn-outline-light btn-social" href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a class="btn btn-outline-light btn-social" href=""
                ><i class="fab fa-youtube"></i
              ></a>
              <a class="btn btn-outline-light btn-social" href=""
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <h4 class="text-white mb-3">Galería</h4>
            <div class="row g-2 pt-2">
              <div class="col-4">
                <img
                  class="img-fluid bg-light p-1"
                  src="../assets/img/course-1.jpg"
                  alt=""
                />
              </div>
              <div class="col-4">
                <img
                  class="img-fluid bg-light p-1"
                  src="../assets/img/course-2.jpg"
                  alt=""
                />
              </div>
              <div class="col-4">
                <img
                  class="img-fluid bg-light p-1"
                  src="../assets/img/course-3.jpg"
                  alt=""
                />
              </div>
              <div class="col-4">
                <img
                  class="img-fluid bg-light p-1"
                  src="../assets/img/course-2.jpg"
                  alt=""
                />
              </div>
              <div class="col-4">
                <img
                  class="img-fluid bg-light p-1"
                  src="../assets/img/course-3.jpg"
                  alt=""
                />
              </div>
              <div class="col-4">
                <img
                  class="img-fluid bg-light p-1"
                  src="../assets/img/course-1.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <h4 class="text-white mb-3">Noticias</h4>
            <p>Recibe noticias y ofertas sobre nosotros.</p>
            <div class="position-relative mx-auto" style="max-width: 400px">
              <input
                class="form-control border-0 w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Tu email"
              />
              <button
                type="button"
                class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                Inscríbete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a class="border-bottom" href="#">A&G Servis</a>, Todos los
              derechos reservados.

              <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
              Desarrollado por
              <a class="border-bottom" href="https://htmlcodex.com"
                >HTML Codex</a
              ><br /><br />
              Distribuido por
              <a class="border-bottom" href="https://themewagon.com"
                >ThemeWagon</a
              >
            </div>
            <!--
            <div class="col-md-6 text-center text-md-end">
              <div class="footer-menu">
                <a href="">Home</a>
                <a href="">Cookies</a>
                <a href="">Help</a>
                <a href="">FQAs</a>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
    <!-- Footer End -->

    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"
      ><i class="bi bi-arrow-up"></i
    ></a>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
