<template>
  <div class="inicio">
    <Carousel2 />
    <About />
    <Services />
    <!--<Galeria /> -->
    <!--<Testimonial /> -->
    <TestimonialPrueba />
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel2 from "../components/Carousel2.vue";
//import Testimonial from "@/components/Testimonial.vue";
import Services from "../components/Services.vue";
import About from "../components/About.vue";
import TestimonialPrueba from "../components/TestimonialPrueba.vue";
//import Galeria from "../components/Galeria.vue";

export default {
  name: "InicioView",
  components: {
    Carousel2,
    //Testimonial,
    Services,
    About,
    TestimonialPrueba,
    //Galeria,
  },

  mounted() {
    //var URLhash = window.location.hash;

    document.getElementById("inicio").classList.add("active");
    document.getElementById("nosotros").classList.remove("active");
    document.getElementById("servicios").classList.remove("active");
    document.getElementById("catalogo").classList.remove("active");
    document.getElementById("contacto").classList.remove("active");

    window.document.title = "A&G Servis | Sitio Web";
  },
};
</script>
