<template>
  <div>
    <!-- Service Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <!-- Titulo -->
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 class="section-title bg-white text-center text-primary px-3">
            Servicios
          </h6>
          <h1 class="mb-5">Servicios</h1>
        </div>
        <!-- Fin titulo -->

        <div class="row g-4">
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="service-item text-center pt-3">
              <div class="p-4">
                <i class="fa fa-3x fas fa-fire text-primary mb-4"></i>
                <h5 class="mb-3">Soldadura</h5>
                <p>
                  Contamos con profesionales capacitados en distintos procesos
                  de soldadura.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="service-item text-center pt-3">
              <div class="p-4">
                <i class="fa fa-3x fa-truck-monster text-primary mb-4"></i>
                <h5 class="mb-3">Barrenado</h5>
                <p>
                  Te ayudamos a recuperar y reducir costos de componentes de
                  maquinaria pesada.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="service-item text-center pt-3">
              <div class="p-4">
                <i class="fa fa-3x fa-cogs text-primary mb-4"></i>
                <h5 class="mb-3">Tornería</h5>
                <p>
                  Reparamos y fabricamos piezas mecánicas con los mejores
                  estándares de calidad.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <div class="service-item text-center pt-3">
              <div class="p-4">
                <i class="fa fa-3x fa-tools text-primary mb-4"></i>
                <h5 class="mb-3">Diseño y fabricación</h5>
                <p>
                  Diseñamos y elaboramos todo tipo de estructuras metálicas para
                  industrias y minería.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Service End -->

    <!-- Courses Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 class="section-title bg-white text-center text-primary px-3">
            Nuestros trabajos
          </h6>
          <h1 class="mb-5">Nuestros trabajos</h1>
        </div>

        <div class="row g-4 justify-content-center">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="course-item bg-light">
              <div class="position-relative overflow-hidden">
                <img
                  class="img-fluid"
                  src="../assets/img/course-1.jpg"
                  alt=""
                />
                <!-- 
                  <div
                  class="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4"
                >
                  <a
                    href="#"
                    class="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                    style="border-radius: 30px 0 0 30px"
                    >Read More</a
                  >
                  <a
                    href="#"
                    class="flex-shrink-0 btn btn-sm btn-primary px-3"
                    style="border-radius: 0 30px 30px 0"
                    >Join Now</a
                  >
                </div>
                -->
              </div>
              <!-- 
                <div class="text-center p-4 pb-0">
                <h3 class="mb-0">$149.00</h3>
                <div class="mb-3">
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small>(123)</small>
                </div>
                <h5 class="mb-4">
                  Web Design & Development Course for Beginners
                </h5>
              </div>
              -->

              <!-- 
                <div class="d-flex border-top">
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-user-tie text-primary me-2"></i>John
                  Doe</small
                >
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-clock text-primary me-2"></i>1.49 Hrs</small
                >
                <small class="flex-fill text-center py-2"
                  ><i class="fa fa-user text-primary me-2"></i>30
                  Students</small
                >
              </div>
              -->
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="course-item bg-light">
              <div class="position-relative overflow-hidden">
                <img
                  class="img-fluid"
                  src="../assets/img/course-2.jpg"
                  alt=""
                />

                <!-- 
                  <div
                  class="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4"
                >
                  <a
                    href="#"
                    class="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                    style="border-radius: 30px 0 0 30px"
                    >Read More</a
                  >
                  <a
                    href="#"
                    class="flex-shrink-0 btn btn-sm btn-primary px-3"
                    style="border-radius: 0 30px 30px 0"
                    >Join Now</a
                  >
                </div>
                -->
              </div>

              <!-- 
                <div class="text-center p-4 pb-0">
                <h3 class="mb-0">$149.00</h3>
                <div class="mb-3">
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small>(123)</small>
                </div>
                <h5 class="mb-4">
                  Web Design & Development Course for Beginners
                </h5>
              </div>
              -->

              <div class="d-flex border-top">
                <!-- 
                  <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-user-tie text-primary me-2"></i>John
                  Doe</small
                >
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-clock text-primary me-2"></i>1.49 Hrs</small
                >
                <small class="flex-fill text-center py-2"
                  ><i class="fa fa-user text-primary me-2"></i>30
                  Students</small
                -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="course-item bg-light">
              <div class="position-relative overflow-hidden">
                <img
                  class="img-fluid"
                  src="../assets/img/course-3.jpg"
                  alt=""
                />
                <!-- 
                   <div
                  class="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4"
                >
                  <a
                    href="#"
                    class="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                    style="border-radius: 30px 0 0 30px"
                    >Read More</a
                  >
                  <a
                    href="#"
                    class="flex-shrink-0 btn btn-sm btn-primary px-3"
                    style="border-radius: 0 30px 30px 0"
                    >Join Now</a
                  >
                </div>
                -->
              </div>

              <!-- 
                <div class="text-center p-4 pb-0">
                <h3 class="mb-0">$149.00</h3>
                <div class="mb-3">
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small class="fa fa-star text-primary"></small>
                  <small>(123)</small>
                </div>
                <h5 class="mb-4">
                  Web Design & Development Course for Beginners
                </h5>
              </div>
              -->

              <!-- 
               <div class="d-flex border-top">
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-user-tie text-primary me-2"></i>John
                  Doe</small
                >
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-clock text-primary me-2"></i>1.49 Hrs</small
                >
                <small class="flex-fill text-center py-2"
                  ><i class="fa fa-user text-primary me-2"></i>30
                  Students</small
                >
              </div> 
              -->
            </div>
          </div>
        </div>

        <!--Boton -->

        <div class="container-fluid h-100">
          <div class="row w-100 align-items-center">
            <div class="col text-center">
              <button
                class="btn btn-primary text-center wow fadeInUp py-md-3 px-md-5 me-0"
                style="margin: 20px"
                data-wow-delay="0.5s"
              >
                Leer mas
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Courses End -->
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>

<style scoped></style>
